<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="jobs"
                          columnsPrefix="jobs.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="jobs.title.jobs"
                >
                    <template #row-details="row">
                        <b-table-simple bordered class="no-top" id="z_index_10">
                            <b-thead head-variant="info">
                                <b-th>{{ $t('columns.jobs.id') }}</b-th>
                                <b-th>{{ $t('columns.jobs.status') }}</b-th>
                                <b-th>{{ $t('columns.jobs.data') }}</b-th>
                                <b-th>{{ $t('columns.jobs.error') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="job in row.item.jobs" :key="job.id">
                                <b-td>{{ job.id }}</b-td>
                                <b-td>
                                    <CBadge :color="statusClass(job.status)">{{ job.status }}</CBadge>
                                </b-td>
                                <b-td>{{ job.data }}</b-td>
                                <b-td>{{ job.error }}</b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>

                    <template #cell(id)="row">
                        <a href="#" @click.prevent="row.toggleDetails">{{ row.item.id }}</a>
                    </template>
                    <template #cell(status)="row">
                        <CBadge
                            @click="row.toggleDetails"
                            style="cursor: pointer;"
                            :color="statusClass(row.item.status)">{{ row.item.status }}
                        </CBadge>
                    </template>

                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="row.toggleDetails">
                                {{ $t('common.title.show') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('job-info-modal', {job_group: row.item.group})">
                                {{ $t('jobs.title.job_info') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Jobs',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Jobs', ['getJobsTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Jobs/fetchJobs', 'getJobsTable');
        },
        statusClass(status) {
            if (status == 'queued') {
                return 'info';
            } else if (status == 'handled') {
                return 'primary'
            } else if (status == 'done') {
                return 'success'
            } else if (status == 'failed') {
                return 'warning'
            }
        },
    },
}
</script>